var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-page"},[_c('div',{staticClass:"login-box"},[_c('div',{staticClass:"card card-outline card-primary"},[_c('div',{staticClass:"card-body p-5"},[_vm._m(0),_c('p',{staticClass:"login-box-msg"},[_vm._v(_vm._s(_vm.status))]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{attrs:{"action":"#","method":"post"},on:{"submit":_vm.forgotOTP}},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.email),expression:"formData.email"}],staticClass:"form-control",attrs:{"type":"email","placeholder":"Email","disabled":_vm.mode === 'otp',"required":""},domProps:{"value":(_vm.formData.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "email", $event.target.value)}}}),_c('div',{staticClass:"input-group-append"},[_c('div',{staticClass:"input-group-text"},[_c('span',{staticClass:"fas fa-envelope"})])])]),_c('div',{staticClass:"mb-2"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.mode === 'otp')?_c('ValidationProvider',{attrs:{"rules":'required',"vid":"otp"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.otp),expression:"formData.otp"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"OTP","required":""},domProps:{"value":(_vm.formData.otp)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "otp", $event.target.value)}}}),_c('div',{staticClass:"input-group-append"},[_c('div',{staticClass:"input-group-text"},[_c('span',{staticClass:"fas fa-lock"})])])]),_c('div',{staticClass:"mb-2"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),(_vm.mode === 'otp')?_c('ValidationProvider',{attrs:{"rules":'required|min:6',"vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.password),expression:"formData.password"}],staticClass:"form-control",attrs:{"type":"password","placeholder":"Password","required":""},domProps:{"value":(_vm.formData.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "password", $event.target.value)}}}),_c('div',{staticClass:"input-group-append"},[_c('div',{staticClass:"input-group-text"},[_c('span',{staticClass:"fas fa-lock"})])])]),_c('div',{staticClass:"mb-2"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),_c('div',[_c('button',{staticClass:"btn btn-primary btn-block mt-4",attrs:{"type":"submit","disabled":invalid}},[(_vm.mode === 'forgot')?_c('i',{staticClass:"far fa-envelope"}):_vm._e(),(_vm.mode === 'otp')?_c('i',{staticClass:"fas fa-save"}):_vm._e(),_vm._v(" "+_vm._s(_vm.mode === 'otp'? 'Save': 'Request OTP Code')+" ")])])],1)]}}])}),_c('div',{staticClass:"mt-5"},[_c('div',{staticClass:"mb-2"},[_c('router-link',{staticClass:"text-center",attrs:{"to":{ name: 'Signup' }}},[_c('i',{staticClass:"fas fa-user-plus mr-1"}),_vm._v(" Signup for an account")])],1),_c('router-link',{staticClass:"text-center",attrs:{"to":{ name: 'Login' }}},[_c('i',{staticClass:"fas fa-user-lock mr-1"}),_vm._v(" Already have an account?")])],1)],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center font-size-30 mb-5"},[_c('img',{attrs:{"src":"images/logo-acc-transparent-50w.png"}}),_vm._v(" PoS ")])}]

export { render, staticRenderFns }