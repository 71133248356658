<template>
    <div class="login-page">
        <div class="login-box">
            <!-- /.login-logo -->
            <div class="card card-outline card-primary">
                <div class="card-body p-5">
                    <div class="text-center font-size-30 mb-5">
                        <img src="images/logo-acc-transparent-50w.png" /> PoS
                    </div>
                    <p class="login-box-msg">{{ status }}</p>

                    <ValidationObserver v-slot="{ invalid }">
                    <form action="#" method="post" @submit="forgotOTP">
                        <ValidationProvider v-slot="{ errors }" rules="required|email">
                            <div class="input-group">
                                <input type="email" v-model="formData.email" class="form-control" placeholder="Email" :disabled="mode === 'otp'" required>
                                <div class="input-group-append">
                                    <div class="input-group-text">
                                        <span class="fas fa-envelope"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-2">{{ errors[0] }}</div>
                        </ValidationProvider>
                        <ValidationProvider v-slot="{ errors }" :rules="'required'" vid="otp" v-if="mode === 'otp'">
                            <div class="input-group">
                                <input type="text" v-model="formData.otp" class="form-control" placeholder="OTP" required>
                                <div class="input-group-append">
                                    <div class="input-group-text">
                                        <span class="fas fa-lock"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-2">{{ errors[0] }}</div>
                        </ValidationProvider>
                        <ValidationProvider v-slot="{ errors }" :rules="'required|min:6'" vid="password" v-if="mode === 'otp'">
                            <div class="input-group">
                                <input type="password" v-model="formData.password" class="form-control" placeholder="Password" required>
                                <div class="input-group-append">
                                    <div class="input-group-text">
                                        <span class="fas fa-lock"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-2">{{ errors[0] }}</div>
                        </ValidationProvider>
                        <div>
                            <button type="submit" class="btn btn-primary btn-block mt-4" :disabled="invalid">
                                <i class="far fa-envelope" v-if="mode === 'forgot'"></i>
                                <i class="fas fa-save" v-if="mode === 'otp'"></i>
                                {{ mode === 'otp'? 'Save': 'Request OTP Code' }}
                            </button>
                        </div>
                    </form>
                    </ValidationObserver>

                    <div class="mt-5">
                        <div class="mb-2"><router-link :to="{ name: 'Signup' }" class="text-center"><i class="fas fa-user-plus mr-1"></i> Signup for an account</router-link></div>
                        <router-link :to="{ name: 'Login' }" class="text-center"><i class="fas fa-user-lock mr-1"></i> Already have an account?</router-link>
                    </div>
                </div>
                <!-- /.card-body -->
            </div>
            <!-- /.card -->
        </div>
        <!-- /.login-box -->
    </div>
</template>

<script>
    import Vue from 'vue'
    import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
    import { confirmed, required, min, email } from 'vee-validate/dist/rules';
    extend('required', {
        ...required,
        message: 'This field is required'
    });
    extend('min', {
        ...min,
        message: 'This field must be {length} characters'
    });
    extend('email', {
        ...email,
        message: 'This field must be an email'
    });
    extend('confirmed', {
        ...confirmed,
        message: 'The passwords must match'
    });

    import {UserService} from "@/services/user-service";
    let userService = new UserService();
    export default {
        name: "Forgot_view",
        data: () => {
            return {
                status: 'Recover account access',
                formData: {
                    email: null,
                    otp: null,
                    password: null,
                },
                mode: 'forgot',
            }
        },
        components: {
            ValidationObserver, ValidationProvider
        },
        methods: {
            async forgotOTP(e) {
                e.preventDefault();
                let loader = this.$loading.show();

                userService.formDataForgot = this.formData;
                let response = this.mode === 'forgot'? await userService.forgotOTP(): await userService.forgotReset();
                if(response.isSuccess){
                    Vue.$toast.open({ message: response.message,  type: response.status});
                    if(this.mode === 'forgot'){
                        this.mode = 'otp';
                    }else{
                        let userInitResponse = await userService.userInit();
                        if(userInitResponse.isSuccess){
                            await this.$router.push({name: 'App.Dashboard'});
                        }else{
                            await this.$router.push({name: 'Login'});
                        }
                    }
                }else{
                    console.log(response.status);
                    Vue.$toast.open({ message: response.message,  type: response.status});
                }
                loader.hide();
            },
        },
        created() {
            if(this.$route.params.email && this.$route.params.otp){
                this.mode = 'otp'
                this.formData.email = this.$route.params.email;
                this.formData.otp = this.$route.params.otp;
            }else{
                this.formData.email = null;
                this.formData.otp = null;
                this.formData.password = null;
            }
        }
    }
</script>

<style scoped>

</style>